var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jobSearchDetail" },
    [
      _c("div", { staticClass: "jobSearchDetail__desc" }, [
        _c("div", {
          staticClass: "jobSearchDetail__teaser",
          domProps: { innerHTML: _vm._s(_vm.job.companyProfile) },
        }),
        _vm._v(" "),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.job.jobCustomerLogo,
              expression: "job.jobCustomerLogo",
            },
          ],
          attrs: { src: _vm.job.jobCustomerLogo },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "jobSearchDetail__row" }, [
        _c("div", { staticClass: "jobSearchDetail__info" }, [
          _c(
            "span",
            { staticClass: "jobSearchDetail__icon" },
            [_c("icon", { attrs: { icon: "city" } })],
            1
          ),
          _vm._v("\n            " + _vm._s(_vm.job.city) + "\n        "),
        ]),
        _vm._v(" "),
        _vm.job.salary
          ? _c("div", { staticClass: "jobSearchDetail__info" }, [
              _c(
                "span",
                { staticClass: "jobSearchDetail__icon" },
                [_c("icon", { attrs: { icon: "salary" } })],
                1
              ),
              _vm._v("\n            " + _vm._s(_vm.job.salary) + "\n        "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "jobSearchDetail__info" }, [
          _c(
            "span",
            { staticClass: "jobSearchDetail__icon" },
            [_c("icon", { attrs: { icon: "type" } })],
            1
          ),
          _vm._v("\n            " + _vm._s(_vm.job.type) + "\n        "),
        ]),
        _vm._v(" "),
        _vm.hasContractType
          ? _c("div", { staticClass: "jobSearchDetail__info" }, [
              _c(
                "span",
                { staticClass: "jobSearchDetail__icon" },
                [_c("icon", { attrs: { icon: "contractType" } })],
                1
              ),
              _vm._v(
                "\n            " + _vm._s(_vm.job.contractType) + "\n        "
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "jobSearchDetail__row jobSearchDetail__row--action" },
        [
          _c(
            "div",
            {
              staticClass:
                "jobSearchDetail__column jobSearchDetail__column--action",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [_c("save-job-action", { attrs: { job: _vm.job } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "jobSearchDetail__column jobSearchDetail__column--action",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("share-button", {
                attrs: { title: _vm.job.title, url: _vm.job.url },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "jobSearchDetail__textBlock" }, [
        _c("span", { staticClass: "jobSearchDetail__headline" }, [
          _vm._v(_vm._s(_vm.$t("jobDetail.headline.offer"))),
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "jobSearchDetail__html",
          domProps: { innerHTML: _vm._s(_vm.job.benefits) },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "jobSearchDetail__textBlock" }, [
        _c("span", { staticClass: "jobSearchDetail__headline" }, [
          _vm._v(_vm._s(_vm.$t("jobDetail.headline.tasks"))),
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "jobSearchDetail__html",
          domProps: { innerHTML: _vm._s(_vm.job.responsibilities) },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "jobSearchDetail__textBlock" }, [
        _c("span", { staticClass: "jobSearchDetail__headline" }, [
          _vm._v(_vm._s(_vm.$t("jobDetail.headline.profile"))),
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "jobSearchDetail__html",
          domProps: { innerHTML: _vm._s(_vm.job.requirements) },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "jobSearchDetail__textBlock" },
        [
          _c("span", { staticClass: "jobSearchDetail__headline" }, [
            _vm._v(_vm._s(_vm.$t("jobDetail.headline.apply"))),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "jobSearchDetail__html",
            domProps: { innerHTML: _vm._s(_vm.job.outro) },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "jobSearchDetail__textButtons" },
            [
              _vm.pitchYou
                ? _c(
                    "btn",
                    {
                      staticClass: "jobSearchDetail__btn",
                      attrs: { href: _vm.pitchYou },
                    },
                    [
                      _c("icon", { attrs: { icon: "jobWhatsapp" } }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("jobDetail.btn.pitchYou"))),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.bookMy
                ? _c(
                    "btn",
                    {
                      staticClass: "jobSearchDetail__btn",
                      attrs: { href: _vm.bookMy },
                    },
                    [
                      _c("icon", { attrs: { icon: "jobCalendar" } }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("jobDetail.btn.bookMe"))),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.aivyLink
                ? _c(
                    "btn",
                    {
                      staticClass: "jobSearchDetail__btn",
                      attrs: { href: _vm.aivyLink },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("jobDetail.btn.aivyLink"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.xingLink
                ? _c(
                    "btn",
                    {
                      staticClass: "jobSearchDetail__btn",
                      attrs: { href: _vm.xingLink },
                    },
                    [
                      _c("icon", { attrs: { icon: "jobXing" } }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("jobDetail.btn.xingLink"))),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isMainLanguage
                ? _c(
                    "btn",
                    {
                      staticClass: "jobSearchDetail__btn",
                      attrs: { href: _vm.job.applicationLink },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.onApplicationClick(_vm.job.applicationLink)
                        },
                      },
                    },
                    [
                      _c("icon", { attrs: { icon: "jobBadge" } }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("jobDetail.btn.apply"))),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isMainLanguage
            ? _c("Contact", {
                staticClass: "jobSearchDetail__form",
                attrs: {
                  "additional-payload": {
                    jobId: this.job.internationalId,
                    branchCode: this.job.branchId,
                  },
                  context: this.context.pageContext,
                  email: this.context.data.get("email", ""),
                  token: this.context.data.get("emailToken", ""),
                  "mail-type": "internationalJob",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "jobSearchDetail__note" }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.$t("jobDetail.genderInfo")) },
            }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isMainLanguage && _vm.branch
        ? _c("current-branch", {
            staticClass: "jobSearchDetail__branch",
            attrs: {
              branch: _vm.branch,
              "show-find-other": false,
              override: _vm.branchOverride,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }